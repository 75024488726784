import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import withStyles from "@mui/styles/withStyles";
import { Fab, Tooltip } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Backend from "../common/utils/Backend";
import AddIcon from "@mui/icons-material/Add";

import CreateProjectDialog from "./dialogs/CreateProjectDialog";
import ProjectsTable from "./components/ProjectsTable";

import { authenticationService } from "../common/services";

/**
 * Adds leading zeros to a number
 * @param {Number} num Number
 * @param {Number} size length that the number should have
 * @returns {String} String of number with zero padding
 */
function pad(num, size) {
  var s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
}

/**
 * Generates a default name for new Projects like "Project 001"
 * @param {Object[]} data List of Projects
 * @returns {String} Smart Default name for a new project
 */
function incrementDefaultName(data) {
  let newDefaultName = "Project 001";
  let maxProjNum = 0;
  for (let proj of data) {
    if (proj.name.match("Project [0-9][0-9][0-9]")) {
      const projNum = parseInt(proj.name.replace("Project ", ""), 10);
      let newMaxProjNum = Math.max(maxProjNum, projNum);
      if (newMaxProjNum) maxProjNum = newMaxProjNum;
    }
  }
  newDefaultName = `Project ${pad(maxProjNum + 1, 3)}`;
  return newDefaultName;
}

const styles = (theme) => ({
  root: { height: "100%", overflow: "hidden" },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
});

class HomePage extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      editableProject: null,
      projects: [],
      open: false,
      newDefaultName: "Project 001",
      activeTab: 0,
      serverIsRunning: false,
      user: { group: {} },
    };

    Backend.getCurrentUser((user) => {
      if (user.fullName === null) {
        authenticationService.logout();
      } else {
        this.setState({ user });
      }
    });
    this.checkPythonServer();
  }
  checkPythonServer = () => {
    Backend.isLocalServerReady((result) => {
      if (this.state.serverIsRunning !== result) {
        this.setMountedState({ serverIsRunning: result });
      }
      if (!result) {
        setTimeout(this.checkPythonServer, 2000);
      }
    });
  };

  setMountedState = (stateObject, callback) => {
    if (this._isMounted) {
      this.setState(stateObject, callback);
    }
  };

  handleClickOpen = () => {
    Backend.getLicensingInfo((license) => {
      if (license.licenseStatus === "VALID") {
        this.setMountedState({
          editableProject: null,
          open: true,
        });
      } else {
        authenticationService.logout();
        this.props.history.push("/licensing");
      }
    });
  };

  handleEditOpen = (project) => {
    this.setMountedState({
      editableProject: project,
      open: true,
    });
  };

  handleClose = () => {
    this.setMountedState({
      editableProject: null,
      open: false,
    });
    this.refreshProjects();
  };

  componentDidMount() {
    this._isMounted = true;
    this.refreshProjectsInterval();
    const activeTab = localStorage.getItem("activeProjectTab");
    if (activeTab) this.setMountedState({ activeTab: parseInt(activeTab) });
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.refreshInterval);
  }

  refreshProjectsInterval() {
    this.refreshProjects(() => {
      setTimeout(() => this.refreshProjectsInterval(), 1000);
    });
  }

  /**
   * Refreshes the list of projects.
   * @param {function} callback Runs after projects are refreshed, withouth parameters. Default is empty function.
   * @returns {} Nothing.
   */
  refreshProjects(callback = () => {}) {
    if (!this._isMounted) return;
    Backend.listProjects(
      (data) => {
        let projectsChanged =
          JSON.stringify(this.state.projects) !== JSON.stringify(data);
        if (projectsChanged) {
          this.setMountedState({
            projects: data,
            newDefaultName: incrementDefaultName(data),
          });
        }
        callback();
      },
      (error) => {
        if (this._isMounted && callback) {
          console.error(
            "Error loading projects, reloading page might help. Error: " + error
          );
          callback();
        }
      }
    );
  }
  handleActiveTabChange = (activeTabIndex) => {
    if (this.state.activeTab !== activeTabIndex) {
      this.setMountedState({ activeTab: activeTabIndex });
      localStorage.setItem("activeProjectTab", activeTabIndex);
    }
  };

  render() {
    const { classes } = this.props;
    const { serverIsRunning } = this.state;
    return (
      <div className={classes.root}>
        <ProjectsTable
          onEditProject={this.handleEditOpen}
          projects={this.state.projects}
          activeTab={this.state.activeTab}
          handleActiveTabChange={this.handleActiveTabChange}
          serverIsRunning={serverIsRunning}
          user={this.state.user}
        />
        <Tooltip
          disableInteractive
          title={
            serverIsRunning ? "Add new project" : "Local API Server not ready!"
          }
        >
          <Fab
            className={classes.fab}
            color="primary"
            onClick={() => {
              if (serverIsRunning) {
                this.handleClickOpen();
              } else {
                window.showWarningSnackbar("Local API Server not ready!");
              }
            }}
          >
            {serverIsRunning ? (
              <AddIcon />
            ) : (
              <CircularProgress
                className={classes.serverSpinLoader}
                color="inherit"
              />
            )}
          </Fab>
        </Tooltip>
        <CreateProjectDialog
          project={this.state.editableProject}
          open={this.state.open}
          onClose={this.handleClose}
          newdefaultname={this.state.newDefaultName}
          handleActiveTabChange={this.handleActiveTabChange}
        />
      </div>
    );
  }
}

HomePage.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
};

export default withRouter(withStyles(styles)(HomePage));
